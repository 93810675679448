import { Component } from '@angular/core';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { PartnerDialogComponent } from '../component/partner-dialog/partner-dialog.component';
import { CategoriasService } from 'src/app/shared/services/categorias.service';
import { Categoria } from 'src/app/shared/models/categoria.model';
import { filter, map } from 'rxjs';
@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.scss'],
})
export class PersonasComponent {
  constructor(
    private elementService: ElementService,
    private modalService: NgbModal,
    private keycloakService: KeycloakService,
    private categoriasService: CategoriasService,
  ) {}

  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 48;
  totalItems = 0;

  elementos$: Elemento[] = [];
  isMobileWidth = false;
  odsList: Categoria[] = [];
  etapaList: Categoria[] = [];
  dimensionList: Categoria[] = [];
  modalRef!: NgbModalRef;

  isFirstLoad: boolean = true;

  ngOnInit() {
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }

    this.categoriasService.getAll().subscribe((result: any) => {
      this.odsList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'ods',
      )[0].categorias;
      this.dimensionList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'dimension',
      )[0].categorias;
      this.etapaList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'etapa',
      )[0].categorias;
    });

    this.filtro.patchValue({
      nombre: '',
      dimension: '0',
      copete: '',
    });

    this.filtrar(false);
  }

  public filtro = new FormGroup({
    nombre: new FormControl(),
    dimension: new FormControl(),
    copete: new FormControl(),
  });

  public async filtrar(cargarMas: boolean): Promise<void> {
    this.pageIndex++;
    this.desplazamiento = this.pageIndex * this.limite;
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroParticipantes: ElementoFiltro = new ElementoFiltro();
    filtroParticipantes.rolMinimo = 1;
    filtroParticipantes.estado = 'ACTIVO';
    filtroParticipantes.modulo = 'Personas'; // Concatenar las opciones con una coma o cualquier otro delimitador

    filtroParticipantes.nombre = this.filtro.controls['nombre'].value;
    filtroParticipantes.copete = this.filtro.controls['copete'].value;
    // const dimension = this.filtro.controls['dimension'].value;
    // filtroParticipantes.categorias = dimension > 0 ? [dimension] : [];

    this.elementService
      .getAll(
        this.limite,
        this.desplazamiento,
        filtroParticipantes,
        'nombre-asc',
      )
      .pipe(
        map(data => ({
          total: data.total,
          elementos: data.elementos.filter(elem =>
            ['persona física', 'persona jurídica', 'otros a definir'].includes(
              elem?.tipoModulo?.nombre?.toLowerCase().trim(),
            ),
          ),
        })),
      )
      .subscribe(data => {
        if (cargarMas) {
          this.elementos$ = [...this.elementos$, ...data.elementos];
        } else {
          this.elementos$ = data.elementos;
          if (!this.isFirstLoad) {
            const elementToScroll = document.querySelector('.filters-section');
            elementToScroll?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
          this.isFirstLoad = false;
        }
        this.totalItems = data.total;
      });
  }

  public resetear(): void {
    this.filtro.reset({
      nombre: '',
      dimension: '0',
      copete: '',
    });
    this.filtrar(false);
  }

  public loadMore() {
    this.desplazamiento = this.pageIndex * this.limite;
    this.filtrar(true);
  }

  openModal(id: number) {
    this.modalRef = this.modalService.open(PartnerDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
    });
    this.modalRef.componentInstance.id = id;
  }
}
