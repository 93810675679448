import { inject, InjectionToken } from '@angular/core';
import { SelectedComponentType } from '../types';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { lastValueFrom } from 'rxjs';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';

export const communityDataToken = new InjectionToken('CommunityData');

export type DataBasicType = {
  title: string;
  description: string;
};

export interface DataIniciativasType extends DataBasicType {
  linkProponer: string;
}
export interface DataParticipantesType extends DataBasicType {}
export interface DataComprimisosType extends DataBasicType {
  linkProponer: string;
}

type AnyDataType =
  | DataComprimisosType
  | DataIniciativasType
  | DataParticipantesType;

export abstract class CommunityDataAbstractService {
  protected abstract nameForCommitmentsApi: string;
  abstract readonly logoUrl: string;
  abstract readonly logoAlt: string;
  protected abstract module_prefix: string;
  protected abstract api_base: string;
  protected abstract title: string;
  protected abstract description: string;
  protected abstract iniciativas_data: DataIniciativasType;
  protected abstract participantes_data: DataParticipantesType;
  protected abstract compromisos_data: DataComprimisosType;
  abstract readonly sidebarItems: SelectedComponentType[];
  protected elementService = inject(ElementService);
  protected commitmentsService = inject(CompromisoService);

  constructor() {}

  getCommunityInfo() {
    return { title: this.title, description: this.description };
  }

  getInfo(name: string): AnyDataType {
    return (this as any)[`${name}_data`];
  }

  async getData(
    module: string,
    filtro: ElementoFiltro,
    pageIndex: number,
    limit: number,
  ): Promise<{ elementos: Elemento[]; total: number }> {
    filtro.modulo = module;
    const data = await lastValueFrom(
      this.elementService.getAll(
        limit,
        pageIndex * limit,
        filtro,
        'nombre-asc',
      ),
    );
    return data;
  }

  async getCompromisos() {
    return await this.commitmentsService.getCompromisosPorComunidad(
      this.nameForCommitmentsApi,
    );
  }
}
