<section class="members">
  <!-- begin Module -->
  <div class="module module--bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-center headline">
          <h1 class="typography__headline">Comunidades</h1>
          <p>
            <strong
              >Uniendo comunidades para multiplicar el Impacto del Propósito
              compartido</strong
            >
          </p>
          <p>
            Esperanzas y sueños se entrelazan, forjando un futuro donde
            sostenibilidad y solidaridad impulsan tanto el impacto social,
            ambiental como el económico, abriendo caminos hacia negocios
            innovadores.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Detalle debajo del nombre - Waves -->
  <svg
    id="wave"
    style="transform: rotate(180deg); transition: 0.3s"
    viewBox="0 0 1440 120"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
        <stop stop-color="rgba(245.552, 255, 254.73, 1)" offset="0%"></stop>
        <stop stop-color="rgba(205, 240, 239, 1)" offset="100%"></stop>
      </linearGradient>
    </defs>
    <path
      style="transform: translate(0, 0px); opacity: 1"
      fill="url(#sw-gradient-0)"
      d="M0,96L60,96C120,96,240,96,360,90C480,84,600,72,720,72C840,72,960,84,1080,90C1200,96,1320,96,1440,90C1560,84,1680,72,1800,64C1920,56,2040,52,2160,46C2280,40,2400,32,2520,34C2640,36,2760,48,2880,52C3000,56,3120,52,3240,56C3360,60,3480,72,3600,72C3720,72,3840,60,3960,48C4080,36,4200,24,4320,28C4440,32,4560,52,4680,56C4800,60,4920,48,5040,44C5160,40,5280,44,5400,46C5520,48,5640,48,5760,42C5880,36,6000,24,6120,22C6240,20,6360,28,6480,36C6600,44,6720,52,6840,48C6960,44,7080,28,7200,18C7320,8,7440,4,7560,14C7680,24,7800,48,7920,56C8040,64,8160,56,8280,46C8400,36,8520,24,8580,18L8640,12L8640,120L8580,120C8520,120,8400,120,8280,120C8160,120,8040,120,7920,120C7800,120,7680,120,7560,120C7440,120,7320,120,7200,120C7080,120,6960,120,6840,120C6720,120,6600,120,6480,120C6360,120,6240,120,6120,120C6000,120,5880,120,5760,120C5640,120,5520,120,5400,120C5280,120,5160,120,5040,120C4920,120,4800,120,4680,120C4560,120,4440,120,4320,120C4200,120,4080,120,3960,120C3840,120,3720,120,3600,120C3480,120,3360,120,3240,120C3120,120,3000,120,2880,120C2760,120,2640,120,2520,120C2400,120,2280,120,2160,120C2040,120,1920,120,1800,120C1680,120,1560,120,1440,120C1320,120,1200,120,1080,120C960,120,840,120,720,120C600,120,480,120,360,120C240,120,120,120,60,120L0,120Z"
    ></path>
    <defs>
      <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
        <stop stop-color="rgba(205, 240, 239, 1)" offset="0%"></stop>
        <stop stop-color="rgba(205, 240, 239, 0)" offset="100%"></stop>
      </linearGradient>
    </defs>
    <path
      style="transform: translate(0, 50px); opacity: 0.9"
      fill="url(#sw-gradient-1)"
      d="M0,48L60,54C120,60,240,72,360,64C480,56,600,28,720,24C840,20,960,40,1080,50C1200,60,1320,60,1440,56C1560,52,1680,44,1800,34C1920,24,2040,12,2160,24C2280,36,2400,72,2520,84C2640,96,2760,84,2880,76C3000,68,3120,64,3240,60C3360,56,3480,52,3600,52C3720,52,3840,56,3960,52C4080,48,4200,36,4320,26C4440,16,4560,8,4680,16C4800,24,4920,48,5040,56C5160,64,5280,56,5400,62C5520,68,5640,88,5760,92C5880,96,6000,84,6120,72C6240,60,6360,48,6480,46C6600,44,6720,52,6840,46C6960,40,7080,20,7200,18C7320,16,7440,32,7560,50C7680,68,7800,88,7920,94C8040,100,8160,92,8280,92C8400,92,8520,100,8580,104L8640,108L8640,120L8580,120C8520,120,8400,120,8280,120C8160,120,8040,120,7920,120C7800,120,7680,120,7560,120C7440,120,7320,120,7200,120C7080,120,6960,120,6840,120C6720,120,6600,120,6480,120C6360,120,6240,120,6120,120C6000,120,5880,120,5760,120C5640,120,5520,120,5400,120C5280,120,5160,120,5040,120C4920,120,4800,120,4680,120C4560,120,4440,120,4320,120C4200,120,4080,120,3960,120C3840,120,3720,120,3600,120C3480,120,3360,120,3240,120C3120,120,3000,120,2880,120C2760,120,2640,120,2520,120C2400,120,2280,120,2160,120C2040,120,1920,120,1800,120C1680,120,1560,120,1440,120C1320,120,1200,120,1080,120C960,120,840,120,720,120C600,120,480,120,360,120C240,120,120,120,60,120L0,120Z"
    ></path>
  </svg>
  <div class="module module--white pt-10">
    <!-- begin Filters -->
    <!-- <div class="filter mb-4 mb-sm-6">
        <mat-expansion-panel [expanded]="!isMobileWidth" class="mat-elevation-z0 filter__inputs">
          <mat-expansion-panel-header>
            <mat-panel-title>Ver filtros</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container-fluid">
            <form #formFilterMembers="ngForm" class="form" [formGroup]="filtro">
              <div class="row align-items-md-center">
                <div class="col-md-10">
                  <div class="filter__fields">
                    <div>
                      <mat-form-field>
                        <mat-label>Nombre</mat-label>
                        <input type="search" matInput formControlName="nombre">
                      </mat-form-field>
                    </div>
                    <ng-container *ngFor="let categoria of categorias$">
                          <div *ngIf="categoria.tipo_clasificacion == 'impacto' ">
                            <mat-form-field>
                              <mat-label>{{categoria.nombre}}</mat-label>
                              <mat-select formControlName="categoria{{categoria.orden}}" multiple>
                                <mat-option *ngFor="let cat of categoria.categorias" [value]="cat.id">{{cat.nombre}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div *ngIf="categoria.tipo_clasificacion != 'certificacion' && categoria.tipo_clasificacion != 'impacto' ">
                            <mat-form-field>
                              <mat-label>{{categoria.nombre}}</mat-label>
                              <mat-select formControlName="categoria{{categoria.orden}}">
                                <mat-option *ngFor="let cat of categoria.categorias" [value]="cat.id">{{cat.nombre}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </ng-container>

                    <div>
                      <mat-form-field>
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="estadoModulo">
                          <mat-option value="0">Ninguno</mat-option>
                          <mat-option *ngFor="let moduloEstado of moduloEstados$" [value]="moduloEstado.id">{{moduloEstado.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field>
                        <mat-label>Institución</mat-label>
                        <mat-select formControlName="institucion">
                          <mat-option value="0">Ninguno</mat-option>
                          <mat-option *ngFor="let inst of instituciones$" [value]="inst.nombre">{{inst.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field>
                        <mat-label>País</mat-label>
                        <mat-select formControlName="pais">
                          <mat-option value="0">Ninguno</mat-option>
                          <mat-option *ngFor="let pais of paises$" [value]="pais.id">{{pais.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 mt-3 mt-md-0 pl-md-4">
                  <button class="mat-button--block" mat-button mat-flat-button color="accent"
                    (click)="filtrar(false)">Filtrar</button>
                  <button class="mat-button--block mt-2" mat-button mat-flat-button color="link"
                    (click)="resetear()">Borrar filtros</button>
                </div>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </div> -->
    <!-- end Filters -->
    <!-- begin Module -->
    <div class="container">
      <div class="grid">
        <div class="cards-container">
          <ng-container *ngFor="let elemento of elementos$">
            <div
              *ngIf="elemento.nombre.toLowerCase().trim() !== 'fibras'"
              class="card"
              style="width: 20rem"
            >
              <a
                *ngIf="isCommunityPanel(elemento)"
                href="#"
                [routerLink]="elemento.id.toString()"
              >
                <img
                  src="{{
                    elemento['listadoImage'].startsWith('http')
                      ? elemento['listadoImage']
                      : img_base + elemento['listadoImage']
                  }}"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">{{ elemento.nombre }}</h5>
                  <p class="card-text">{{ elemento.copete }}</p>
                </div>
              </a>
              <a
                *ngIf="!isCommunityPanel(elemento) && elemento.link"
                [href]="elemento.link"
                target="_blank"
              >
                <img
                  src="{{
                    elemento['listadoImage'].startsWith('http')
                      ? elemento['listadoImage']
                      : img_base + elemento['listadoImage']
                  }}"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">{{ elemento.nombre }}</h5>
                  <p class="card-text">{{ elemento.copete }}</p>
                </div>
              </a>
              <a *ngIf="!isCommunityPanel(elemento) && !elemento.link">
                <img
                  src="{{
                    elemento['listadoImage'].startsWith('http')
                      ? elemento['listadoImage']
                      : img_base + elemento['listadoImage']
                  }}"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">{{ elemento.nombre }}</h5>
                  <p class="card-text">{{ elemento.copete }}</p>
                </div>
              </a>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="text-center mt-5" *ngIf="pageIndex * limite < totalItems">
        <!-- <button class="mat-button-xs--block" mat-flat-button color="white" (click)="cargarMas()">CARGAR MÁS</button> -->
      </div>
    </div>
  </div>
  <!-- end Module -->
</section>
