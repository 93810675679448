import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, of } from 'rxjs';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import {
  CommunityDataAbstractService,
  DataComprimisosType,
  DataIniciativasType,
  DataParticipantesType,
} from './community-data';
import { Comunidad, SelectedComponentType } from '../types';
import { environment } from 'src/environments/environment';
import { Categoria } from 'src/app/shared/models/categoria.model';

@Injectable({ providedIn: 'root' })
export class CommunityPanelService extends CommunityDataAbstractService {
  protected override nameForCommitmentsApi!: string;
  override logoUrl!: string;
  override logoAlt!: string;
  protected override module_prefix!: string;
  protected override api_base!: string;
  protected override title!: string;
  protected override description!: string;
  protected override iniciativas_data: DataIniciativasType =
    {} as DataIniciativasType;
  protected override participantes_data: DataParticipantesType =
    {} as DataParticipantesType;
  protected override compromisos_data: DataComprimisosType =
    {} as DataComprimisosType;
  override sidebarItems: SelectedComponentType[] =
    [] as SelectedComponentType[];
  private modulo = 'Comunidades';
  private id!: number;
  private categoria!: Categoria;

  async initialize(id: string | null) {
    if (!Number(id)) return null;
    this.id = Number(id);

    const comm = await lastValueFrom(this.getCommunity());
    console.log(comm);
    if (!comm) return null;

    this.description = comm.descripcion || comm.copete;
    this.title = comm.nombre;
    this.logoUrl = comm?.logoUrl.startsWith('http')
      ? comm.logoUrl
      : environment.img_base + comm.logoUrl;
    this.logoAlt = environment.img_base + comm.logoAlt;
    this.nameForCommitmentsApi = comm.fuente?.trim();
    const { categoria } = comm.categorias.find(
      cat => cat?.categoria?.padre?.nombre === 'Comunidades',
    );
    this.categoria = categoria;
    this.participantes_data.title = categoria?.participantes_titulo;
    this.participantes_data.description = categoria?.participantes_descripcion;
    this.iniciativas_data.title = categoria?.iniciativas_titulo;
    this.iniciativas_data.description = categoria?.iniciativas_descripcion;
    this.iniciativas_data.linkProponer = categoria?.iniciativas_linkProponer;
    this.compromisos_data.title = categoria?.compromisos_titulo;
    this.compromisos_data.description = categoria?.compromisos_descripcion;
    this.compromisos_data.linkProponer = categoria?.compromisos_linkProponer;

    // @ts-ignore
    this.sidebarItems = Object.keys(comm)
      .filter(prop => prop.startsWith('sidebar'))
      .map(item => ({
        id: item.substring(item.indexOf('_') + 1),
        // @ts-ignore
        desc: comm[item],
      }));
    this.sidebarItems.sort((a, b) => {
      if (a.id < b.id) return 1;
      if (a.id > b.id) return -1;
      return 0;
    });
    console.log(this.sidebarItems);

    return this;
  }

  getCommunityBySlug(slug: string | null) {
    return this.elementService
      .getAll(
        0,
        0,
        { infoAdicional: slug, modulo: this.modulo } as ElementoFiltro,
        '',
      )
      .pipe(map(resp => resp.elementos[0]));
  }

  getCommunity() {
    return this.elementService
      .getAll(
        0,
        0,
        {
          id: this.id,
          modulo: this.modulo,
          estado: 'ACTIVO',
        } as ElementoFiltro,
        '',
      )
      .pipe(map(resp => resp.elementos[0] as Comunidad));
  }

  async getParticipantes(
    limite: number,
    desplazamiento: number,
    filtro: ElementoFiltro,
  ) {
    if (this.title === 'Impact Trade') {
      return this.getParticipantesImpactTrade(limite, desplazamiento, filtro);
    }
    return lastValueFrom(
      this.elementService.getAll(
        limite,
        desplazamiento,
        {
          ...filtro,
          rolMinimo: 1,
          estado: 'ACTIVO',
          categorias: [this.categoria.id],
          modulo: 'Personas, Empresas',
        } as ElementoFiltro,
        '',
      ),
    );
  }

  async getIniciativas(
    limite: number,
    desplazamiento: number,
    filtro: ElementoFiltro,
  ) {
    if (this.title === 'Impact Trade') {
      return this.getIniciativasImpactTrade(limite, desplazamiento, filtro);
    }
    return lastValueFrom(
      this.elementService.getAll(
        limite,
        desplazamiento,
        {
          ...filtro,
          rolMinimo: 1,
          estado: 'ACTIVO',
          categorias: [this.categoria.id],
          modulo: 'Proyectos',
        } as ElementoFiltro,
        '',
      ),
    );
  }

  private getParticipantesImpactTrade(
    limite: number,
    desplazamiento: number,
    filtro: ElementoFiltro,
  ) {
    return this.getData('ImpactTrade_Personas', filtro, desplazamiento, limite);
  }
  private getIniciativasImpactTrade(
    limite: number,
    desplazamiento: number,
    filtro: ElementoFiltro,
  ) {
    return this.getData(
      'ImpactTrade_Iniciativas',
      filtro,
      desplazamiento,
      limite,
    );
  }
}
