import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular'; // Asegúrate de tener esta importación
import { map } from 'rxjs';
import { Categoria } from 'src/app/shared/models/categoria.model';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { CategoriasService } from 'src/app/shared/services/categorias.service';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proyects',
  templateUrl: './proyects.component.html',
  styleUrls: ['./proyects.component.scss'],
})
export class ProyectsComponent implements OnInit {
  isLoggedIn = false;
  esSuscriptor: boolean = false;

  constructor(
    private elementService: ElementService,
    private keycloakService: KeycloakService,
    private suscripcionService: SuscripcionService,
    private categoriasService: CategoriasService,
  ) {}

  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 48;
  totalItems = 0;
  elementos$: Elemento[] = [];
  isMobileWidth = false;
  odsList: Categoria[] = [];
  etapaList: Categoria[] = [];
  dimensionList: Categoria[] = [];
  filtroUI!: FormGroup;
  showFilters = false;
  isFirstLoad = true;

  ngOnInit() {
    this.suscripcionService.isSuscriptor().subscribe(result => {
      this.esSuscriptor = result;
    });
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }
    this.checkLoginStatus();

    this.categoriasService.getAll().subscribe((result: any) => {
      this.odsList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'ods',
      )[0].categorias;
      this.dimensionList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'dimension',
      )[0].categorias;
      this.etapaList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'etapa',
      )[0].categorias;
    });

    this.filtroUI = new FormGroup({
      nombre: new FormControl({ value: '', disabled: true }, null),
      dimension: new FormControl({ value: '0', disabled: true }, null),
      ods: new FormControl({ value: '0', disabled: true }, null),
      etapa: new FormControl({ value: '0', disabled: true }, null),
    });
    this.filtroUI.enable();
    this.filtroUI.patchValue({
      nombre: '',
      dimension: '0',
      ods: '0',
      etapa: '0',
    });

    this.filtrar(false);
  }

  idComparator(a: any, b: any): boolean {
    if (a && b) {
      return Number(a['id']) === Number(b['id']);
    }
    return false;
  }

  public filtrar(cargarMas: boolean): void {
    this.pageIndex++;
    this.desplazamiento = this.pageIndex * this.limite;
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    const filtroProyectos: ElementoFiltro = new ElementoFiltro();
    filtroProyectos.estado = 'activo';
    filtroProyectos.modulo = 'Proyectos';
    filtroProyectos.tipoModulo = 'proyecto';
    filtroProyectos.nombre = this.filtroUI.controls['nombre'].value;
    const { nombre, ...cats } = this.filtroUI.value;
    filtroProyectos.categorias = Object.values(cats).filter(v => Number(v) > 0);

    this.elementService
      .getAll(this.limite, this.desplazamiento, filtroProyectos, 'nombre-asc')
      .pipe(
        map(data => ({
          total: data.total,
          elementos: data.elementos.filter(elem =>
            ['proyecto'].includes(
              elem?.tipoModulo?.nombre?.toLowerCase().trim(),
            ),
          ),
        })),
      )
      .subscribe(data => {
        if (cargarMas) {
          this.elementos$ = [...this.elementos$, ...data.elementos];
        } else {
          this.elementos$ = data.elementos;
          if (!this.isFirstLoad) {
            const elementToScroll = document.querySelector('.filters-section');
            elementToScroll?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
        this.totalItems = data.total;
      });
  }

  public resetear(): void {
    this.filtroUI.reset({
      nombre: '',
      dimension: '0',
      ods: '0',
      etapa: '0',
    });
    this.filtrar(false);
  }

  // public cargarMas() {
  //   this.desplazamiento = this.pageIndex * this.limite;
  //   this.filtrar(true);
  // }

  checkLoginStatus() {
    this.keycloakService.isLoggedIn().then(loggedIn => {
      this.isLoggedIn = loggedIn;
    });
  }
}
