import {
  EnvironmentInjector,
  inject,
  Injector,
  runInInjectionContext,
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CommunityPanelService } from './community-panel.service';
import { Elemento } from 'src/app/shared/models/elemento.model';

export const CommunityDataResolver: ResolveFn<Promise<void>> = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const id = route.paramMap.get('id');
  const injector = inject(EnvironmentInjector);
  const comm = await inject(CommunityPanelService).initialize(id);
  if (!comm)
    runInInjectionContext(injector, () => {
      inject(Router).navigate(['/']);
    });
};
