import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Elemento } from '../models/elemento.model';
import { ElementoFiltro } from '../models/elementoFiltro.model';

// import { analyzeAndValidateNgModules } from '@angular/compiler';
// import { element } from 'protractor';
// import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ElementService {
  constructor(private httpClient: HttpClient) {}

  currentDate = new Date();
  checkoutDate = new Date().setDate(this.currentDate.getDate() + 7);

  public getAll(
    limite: number,
    desplazamiento: number,
    elementoFiltro: ElementoFiltro,
    order: string,
  ): Observable<{ elementos: Elemento[]; total: number }> {
    let params = new HttpParams();

    if (limite) params = params.append('limite', limite.toString());
    if (desplazamiento)
      params = params.append('desplazamiento', desplazamiento.toString());

    if (elementoFiltro) {
      if (elementoFiltro.id)
        params = params.append('id', elementoFiltro.id.toString());
      if (elementoFiltro.categorias && elementoFiltro.categorias.length > 0)
        params = params.append('categoria', elementoFiltro.categorias.join());
      if (elementoFiltro.tipoModulo)
        params = params.append('tipoModuloNombre', elementoFiltro.tipoModulo);
      if (elementoFiltro.nombre)
        params = params.append('nombre', elementoFiltro.nombre);
      if (elementoFiltro.copete)
        params = params.append('copete', elementoFiltro.copete);
      if (elementoFiltro.descripcion)
        params = params.append('descripcion', elementoFiltro.descripcion);
      if (elementoFiltro.descripcionHtml)
        params = params.append(
          'descripcionHtml',
          elementoFiltro.descripcionHtml,
        );
      if (elementoFiltro.fuente)
        params = params.append('fuente', elementoFiltro.fuente);
      if (elementoFiltro.estadoModulo)
        params = params.append('estadoModulo', elementoFiltro.estadoModulo);
      if (elementoFiltro.fechaInfoInicio)
        params = params.append(
          'fechaInicioInfo',
          elementoFiltro.fechaInfoInicio.toString(),
        );
      if (elementoFiltro.fechaInicioInfoDesde)
        params = params.append(
          'fechaInicioInfoDesde',
          elementoFiltro.fechaInicioInfoDesde.toString(),
        );
      if (elementoFiltro.fechaInicioInfoHasta)
        params = params.append(
          'fechaInicioInfoHasta',
          elementoFiltro.fechaInicioInfoHasta.toString(),
        );
      if (elementoFiltro.fechaInfoFin)
        params = params.append(
          'fechaFinInfo',
          elementoFiltro.fechaInfoFin.toString(),
        );
      if (elementoFiltro.fechaFinInfoDesde)
        params = params.append(
          'fechaFinInfoDesde',
          elementoFiltro.fechaFinInfoDesde.toString(),
        );
      if (elementoFiltro.fechaFinInfoHasta)
        params = params.append(
          'fechaFinInfoHasta',
          elementoFiltro.fechaFinInfoHasta.toString(),
        );
      if (elementoFiltro.institucion)
        params = params.append('institucion', elementoFiltro.institucion);
      if (elementoFiltro.pais)
        params = params.append('pais', elementoFiltro.pais);
      if (elementoFiltro.lenguaje)
        params = params.append('lenguaje', elementoFiltro.lenguaje);
      if (elementoFiltro.estado)
        params = params.append('estado', elementoFiltro.estado);
      if (elementoFiltro.fechaInicio)
        params = params.append(
          'fechaInicio',
          elementoFiltro.fechaInicio.getUTCDate().toString(),
        );
      if (elementoFiltro.fechaFin)
        params = params.append(
          'fechaFin',
          elementoFiltro.fechaFin.getUTCDate().toString(),
        );
      if (elementoFiltro.rolMinimo)
        params = params.append(
          'rolMinimo',
          elementoFiltro.rolMinimo.toString(),
        );
      if (elementoFiltro.modulo)
        params = params.append('modulo', elementoFiltro.modulo);
      if (elementoFiltro.orden)
        params = params.append('orden', elementoFiltro.orden.toString());
      if (elementoFiltro.ordenDestacado)
        params = params.append(
          'ordenDestacado',
          elementoFiltro.ordenDestacado.toString(),
        );
      if (elementoFiltro.esDestacado)
        params = params.append(
          'destacado',
          elementoFiltro.esDestacado.toString(),
        );
    }
    if (order) params = params.append('order', order);

    return this.httpClient.get<{ elementos: Elemento[]; total: number }>(
      environment.api_base + environment.tenant + '/elementos',
      { params },
    );
  }

  public filterProximosEventos(eventos_all: Elemento[]): Elemento[] {
    return eventos_all
      .filter(
        element =>
          (new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0) <=
            this.currentDate.setHours(0, 0, 0, 0) &&
            new Date(element.fechaInfoFin).setHours(0, 0, 0, 0) >=
              this.currentDate.setHours(0, 0, 0, 0)) ||
          (new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0) >=
            this.currentDate.setHours(0, 0, 0, 0) &&
            new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0) <=
              this.checkoutDate) ||
          (new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0) >=
            this.currentDate.setHours(0, 0, 0, 0) &&
            new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0) <=
              this.checkoutDate),
      )
      .reverse();
  }

  public filterFuturosEventos(eventos_all: Elemento[]): Elemento[] {
    return eventos_all
      .filter(element => {
        console.log(
          'element.fechaInfoInicio ',
          new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0),
        );
        console.log('checkoutDate ', this.checkoutDate);
        console.log(
          'element.fechaInfoInicio > checkoutDate ',
          new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0) >
            this.checkoutDate,
        );
        return (
          new Date(element.fechaInfoInicio).setHours(0, 0, 0, 0) >
          this.checkoutDate
        );
      })
      .reverse();
  }

  public filterEventosPasados(eventos_all: Elemento[]): Elemento[] {
    return eventos_all.filter(
      element =>
        new Date(element.fechaInfoFin).setHours(0, 0, 0, 0) <
        this.currentDate.setHours(0, 0, 0, 0),
    );
  }

  public updateElement(c: any): Observable<Elemento> {
    return this.httpClient.put<Elemento>(
      environment.api_base + environment.tenant + '/elementos/' + c.id,
      c,
    );
  }

  public createElement(c: any): Observable<Elemento> {
    return this.httpClient.post<Elemento>(
      environment.api_base + environment.tenant + '/elementos',
      c,
    );
  }

  public deleteElement(c: any): Observable<any> {
    return this.httpClient.delete(
      environment.api_base + environment.tenant + '/elementos/' + c.id,
      c,
    );
  }
}
