import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private buttonDonateSubject = new BehaviorSubject<boolean>(false);
  buttonDonate$ = this.buttonDonateSubject.asObservable();

  setDonateButtonActive(active: boolean): void {
    this.buttonDonateSubject.next(active);
  }

  getDonateButtonState(): boolean {
    return this.buttonDonateSubject.getValue();
  }
}
