import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { map, Observable } from 'rxjs';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';

export const CommunityCommitmentsResolver: ResolveFn<
  Observable<Elemento> | Promise<boolean>
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const id = route.paramMap.get('id');
  return inject(ElementService)
    .getAll(
      0,
      0,
      { modulo: 'Comunidades', id: Number(id) } as ElementoFiltro,
      '',
    )
    .pipe(
      map(data => {
        return data.elementos[0];
      }),
    );
};
