import { Component, inject } from '@angular/core';
import { SelectedComponentType } from '../types';
import {
  CommunityDataAbstractService,
  communityDataToken,
  DataBasicType,
} from './community-data';
import { CommunityPanelService } from './community-panel.service';

@Component({
  selector: 'app-community-panel',
  templateUrl: './community-panel.component.html',
  styleUrls: ['./community-panel.component.scss'],
  providers: [
    {
      provide: communityDataToken,
      useExisting: CommunityPanelService,
    },
  ],
})
export class CommunityPanelComponent {
  selectedComponent: SelectedComponentType = {
    id: 'iniciativas',
    desc: 'Iniciativas',
  };

  info: DataBasicType =
    inject<CommunityDataAbstractService>(communityDataToken).getCommunityInfo();

  onSelectedComp(comp: SelectedComponentType) {
    this.selectedComponent = comp;
    if (window.innerWidth > 992)
      window.scrollTo({ top: 450, left: 0, behavior: 'instant' });
    else window.scrollTo({ top: 500, left: 0, behavior: 'instant' });
  }
}
