import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { filter, map, Observable } from 'rxjs';

export const CommunitiesResolver: ResolveFn<Observable<Elemento[]>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(ElementService)
    .getAll(0, 0, { modulo: 'Comunidades' } as ElementoFiltro, '')
    .pipe(
      map(data =>
        data.elementos.filter(
          element => element?.estadoModulo?.nombre === 'MOSTRAR',
        ),
      ),
    );
};
