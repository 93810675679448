<section>
  <header>
    <div class="row-1">
      <div class="col">
        <!-- BOTON PARA PROBAR MODOS - QUITAR LUEGO -->
        <a *ngIf="showMagicButton" (click)="onOffSuscriptor()"><i class="fa-solid fa-language"></i></a>
      </div>
      <div class="col col2">
        <ul class="rrss">
          <li>
            <a href="https://www.facebook.com/fibras.uy" target="_blank">
              <i class="bi bi-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/fibras/" target="_blank">
              <i class="bi bi-linkedin"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/fibras.uy/" target="_blank">
              <i class="bi bi-instagram"></i>
            </a>
          </li>
          <li>
            <div>
              <a (click)="login()" class="nav-link link-body-emphasis px-2" *ngIf="!user">Iniciar sesión</a>
              <div ngbDropdown class="d-inline-block" *ngIf="user">
                <a type="button" class="d-block text-decoration-none dropdown-toggle" id="dropdownUser"
                  ngbDropdownToggle>
                  {{ user.firstName }} {{ user.lastName }}
                </a>
                <div ngbDropdownMenu aria-labelledby="dropdownUser">
                  <span ngbDropdownItem routerLink="/user" style="cursor: pointer;">
                    Editar datos
                  </span>
                  <!-- <button ngbDropdownItem (click)="goPayments();focusElem($event,false)">Pagos</button>
                                    <button ngbDropdownItem (click)="goInvoices();focusElem($event,false)">Facturas</button> -->
                  <button ngbDropdownItem (click)="logOut()">
                    Cerrar sesión
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row-2">
      <div class="col-3">
        <a href="#" routerLink="/" class="link-logo" (click)="focusElem($event, false)">
          <img src="/assets/img/ui/logo-fibras.png" alt="" class="logo" />
        </a>
        <nav class="navbar menu">
          <ul>
            <li *ngFor="let route of getDisplayableRoutes()">
              <a *ngIf="!route.href" href="#" class="link" [routerLink]="route.link" (click)="onClickLink($event)">
                {{ route.title }}
              </a>
              <a *ngIf="route.href" [href]="route.link" class="link" target="_blank" (click)="onClickLink($event)">
                {{ route.title }}
              </a>
            </li>
            <!-- <li *ngIf="esSuscriptor">
              <a
                href="#"
                class="link text-nowrap"
                routerLink="market"
                (click)="focusElem($event, true)"
                >Planes</a
              >
            </li> -->
          </ul>
        </nav>


      </div>

      <a class="associate" (click)="buttonClick(); focusElem($event, true)">
        <div class="col-4">
          <span class="border-animation"></span>
          <h6 [ngClass]="getButtonClass()">
            {{ esSuscriptor ? "PLATAFORMA" : "UNITE" }}
          </h6>
        </div>
      </a>

      <nav class="navbar menu-desplegable">
        <a class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </a>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" [ngClass]="{ show: isNavbarOpen }"
          aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-center flex-grow-1">
              <li *ngFor="let route of getDisplayableRoutes()">
                <a *ngIf="!route.href" href="#" class="link" [routerLink]="route.link" (click)="onClickLink($event)">
                  {{ route.title }}
                </a>
                <a *ngIf="route.href" [href]="route.link" class="link" target="_blank" (click)="onClickLink($event)">
                  {{ route.title }}
                </a>
              </li>
              <li>
                <a class="link" (click)="onClickLink($event); this.buttonClick()">
                  <div>
                    <span></span>
                    <h6 [ngClass]="getButtonClass()" style="
                        font-weight: bold;
                        padding: 10px;
                        border: #fff 3px solid;
                        border-radius: 50px;
                        margin-top: 10px;
                      ">
                      {{ esSuscriptor ? "PLATAFORMA" : "UNITE" }}
                    </h6>
                  </div>
                </a>
              </li>
              <div class="rrhh-offcanvas">
                <li class="menu-item">
                  <a href="https://www.facebook.com/fibras.uy" target="_blank">
                    <i class="bi bi-facebook"></i>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="https://www.linkedin.com/company/fibras/" target="_blank">
                    <i class="bi bi-linkedin"></i>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="https://www.instagram.com/fibras.uy/" target="_blank">
                    <i class="bi bi-instagram"></i>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <a *ngIf="isDonateButtonActive" href="/marketplace/es/product/donate/purchase/donacion">
      <div class="donateButton">
        <img src="/assets/icon/donate-icon.png">
        <div class="buttonText">
          <span>Donar</span>
        </div>
      </div>
    </a>
  </header>
</section>